import React from 'react';
import { isMobile } from 'react-device-detect';
// import ReactGA from 'react-ga';
// import OneSignal from 'react-onesignal';
import { BrowserRouter, Router } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

import { createBrowserHistory } from 'history';
import Routes from 'routes';

import { ChakraProvider } from '@chakra-ui/react';

import ModalRoot from 'components/Modal/Root';

import { AuthProvider } from 'contexts/AuthContext';
import { ModalProvider } from 'contexts/ModalContext';
import { TimezoneProvider } from 'contexts/TimezoneContext';

import customTheme from 'styles/theme/customTheme';

import 'react-toastify/dist/ReactToastify.css';
import 'react-perfect-scrollbar/dist/css/styles.css';

import 'styles/css/all.scss';

const history = createBrowserHistory();

// history.listen((location) => {
//   ReactGA.set({ page: location.pathname }); // Update the user's current page
//   ReactGA.pageview(location.pathname + location.search); // Record a pageview for the given page

//   console.log(location.pathname + location.search);
// });

const App: React.FC = () => {
  // useEffect(() => {
  //   if (process.env.REACT_APP_ONESIGNAL_APP_ID) {
  //     OneSignal.initialize(process.env.REACT_APP_ONESIGNAL_APP_ID, {
  //       safari_web_id: process.env.REACT_APP_ONESIGNAL_SAFARI_APP_ID,
  //       notifyButton: {
  //         enable: false,
  //       },
  //       allowLocalhostAsSecureOrigin: true,
  //       autoResubscribe: true,
  //       autoRegister: true,
  //     });
  //   }
  // }, []);

  return (
    <ChakraProvider theme={customTheme}>
      <Router history={history}>
        <BrowserRouter>
          <AuthProvider>
            <TimezoneProvider>
              <ModalProvider>
                <Routes />
                <ModalRoot />
              </ModalProvider>
            </TimezoneProvider>
          </AuthProvider>

          <ToastContainer
            position={
              isMobile ? toast.POSITION.TOP_CENTER : toast.POSITION.TOP_RIGHT
            }
          />
        </BrowserRouter>
      </Router>
    </ChakraProvider>
  );
};

export default App;
