import React from 'react';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  HStack,
  Text,
  Code,
} from '@chakra-ui/react';

import { ModalRootProps } from 'components/Modal/Root';

const ModalTest: React.FC<ModalRootProps> = ({
  data = {},
  mode, // default = add
  onConfirm,
  handleClose,
  ...restProps
}) => {
  const handleConfirm = (): void => {
    if (onConfirm) onConfirm('some data');
    handleClose();
  };

  return (
    <Modal {...restProps}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Modal Teste</ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <Text>
            Data: <Code>{JSON.stringify(data)}</Code>
          </Text>
          <Text>
            Mode: <Code>{mode}</Code>
          </Text>
        </ModalBody>

        <ModalFooter>
          <HStack spacing="2">
            <Button colorScheme="blackAlpha" onClick={handleClose}>
              Cancelar
            </Button>
            <Button colorScheme="green" onClick={handleConfirm}>
              Salvar
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalTest;
