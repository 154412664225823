import React, { createContext, useState } from 'react';
// import { isMobile } from 'react-device-detect';

// import { useBreakpointValue } from '@chakra-ui/react';

// import usePersistedState from 'hooks/usePersistedState';

interface ISidebar {
  open: boolean;
  variant: 'sidebar' | 'drawer';
  toggleSidebar(): void;
  openSidebar(): void;
  closeSidebar(): void;
}

// const smVariant = { navigation: 'drawer', navigationButton: true };
// const mdVariant = { navigation: 'sidebar', navigationButton: false };

const SidebarContext = createContext({} as ISidebar);

export const SidebarProvider: React.FC = ({ children }) => {
  // const [open, setOpen] = usePersistedState<boolean>('sidebarOpen', !isMobile);
  const [open, setOpen] = useState<boolean>(false);

  // const sidebarVariants = useBreakpointValue({
  //   base: smVariant,
  //   md: mdVariant,
  // });

  const toggleSidebar = (): void => {
    setOpen(!open);
  };

  const closeSidebar = (): void => setOpen(false);
  const openSidebar = (): void => setOpen(true);

  return (
    <SidebarContext.Provider
      value={{
        open,
        toggleSidebar,
        // variant: sidebarVariants?.navigation,
        variant: 'drawer',
        openSidebar,
        closeSidebar,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

export default SidebarContext;
