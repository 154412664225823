import React from 'react'; // { Suspense, lazy }
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';

import { G_TRACKING_ID } from 'config/gtag';

import { ColorModeScript } from '@chakra-ui/react';

import App from './App';

// import FallbackSpinner from 'components/FallbackSpinner';

// const LazyApp = lazy(() => import('./App'));

ReactGA.initialize(G_TRACKING_ID);

ReactDOM.render(
  <React.StrictMode>
    <ColorModeScript />

    {/* <Suspense fallback={<FallbackSpinner />}> */}
    <App />
    {/* </Suspense> */}
  </React.StrictMode>,
  document.getElementById('root'),
);
