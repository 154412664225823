import ModalAccountLookup from 'components/Modal/AccountLookup';
import ModalOwnerLookup from 'components/Modal/OwnerLookup';
import ModalRecipientLookup from 'components/Modal/RecipientLookup';
import ModalTest from 'components/Modal/Test';

export default {
  test: ModalTest,
  ownerLookup: ModalOwnerLookup,
  accountLookup: ModalAccountLookup,
  recipientLookup: ModalRecipientLookup,
};
