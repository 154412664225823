import React from 'react';

import { TableBodyProps, Tbody as ChakraTbody } from '@chakra-ui/react';

export const Tbody = (props: TableBodyProps): React.ReactElement => {
  const { children, ...rest } = props;

  return (
    <ChakraTbody {...rest}>
      {children &&
        React.Children.map(children, (child, idx) => {
          return (
            React.isValidElement(child) &&
            React.cloneElement(child, { inBody: true, bodyIdx: idx })
          );
        })}

      {/* {React.isValidElement(children) &&
        React.cloneElement(children, { inBody: true })} */}
    </ChakraTbody>
  );
};
