import React, { useState, createContext } from 'react';

import { ModalProps as ChakraModalProps } from '@chakra-ui/react';

import { ModalKey } from 'components/Modal/Root';

type Response<T> = T;
export interface ModalProps extends ChakraModalProps {
  mode: 'edit' | 'add' | 'lookup';
  data?: { [key: string]: string | number | boolean | Date };
  onConfirm?: (...args) => Response<T>;
}

export interface Modal {
  key: ModalKey;
  props?: ModalProps;
}

export interface IModalContext {
  modals: Modal[];
  openModal: (key: ModalKey, data?: ModalProps) => void;
  closeModal: (key: ModalKey) => void;
}

const ModalContext = createContext({} as IModalContext);

export const ModalProvider: React.FC = ({ children }) => {
  const [list, setList] = useState<Modal[]>([]);

  const openModal = (key: ModalKey, props?: ModalProps): void => {
    setList((oldList) => [...oldList, { key, props }]);
  };

  const closeModal = (key: string): void => {
    setList((oldList) => oldList.filter((modal) => modal.key !== key));
  };

  return (
    <ModalContext.Provider
      value={{
        modals: list,
        openModal,
        closeModal,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export type { ModalKey } from 'components/Modal/Root';
export default ModalContext;
