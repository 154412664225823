import React from 'react';

import { TableCellProps, Td as ChakraTd } from '@chakra-ui/react';

import TableContext from 'contexts/TableContext';

interface ITdInnerProps extends TableCellProps {
  columnKey: number;
  narrowHeaders: Record<number, React.ReactElement>;
  responsiveColumnName: string;
}

export type ITdProps = Omit<ITdInnerProps, 'narrowHeaders'>;

const TdInner = ({
  className,
  children,
  ...restProps
}: ITdInnerProps): React.ReactElement => {
  const { narrowHeaders = {}, columnKey = 0, ...rest } = restProps;
  const classes = `${className || ''} pivoted`;

  return (
    <ChakraTd data-testid="td" {...rest} className={classes}>
      {!restProps?.noData && (
        <div data-testid="td-before" className="tdBefore">
          {restProps?.responsiveColumnName ?? narrowHeaders[columnKey]}
        </div>
      )}

      {children ?? <div>&nbsp;</div>}
    </ChakraTd>
  );
};

export const Td = (props: ITdProps): ChakraTd => (
  <TableContext.Consumer>
    {(headers) => {
      return <TdInner {...props} narrowHeaders={headers} />;
    }}
  </TableContext.Consumer>
);
