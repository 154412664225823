import React from 'react';
import ReactPaginate from 'react-paginate';

import { AngleLeft as AngleLeftIcon } from '@styled-icons/fa-solid/AngleLeft';
import { AngleRight as AngleRightIcon } from '@styled-icons/fa-solid/AngleRight';

import { Flex, Icon } from '@chakra-ui/react';

type PaginationProps = {
  lastPage: number;
  forcePage?: number;
  onPaginate(selectedPage: number): void;
};

const Pagination: React.FC<PaginationProps> = ({
  forcePage,
  lastPage,
  onPaginate,
}) => {
  return (
    <Flex alignItems="center" justifyContent="center" my={3}>
      <ReactPaginate
        previousLabel={<Icon as={AngleLeftIcon} />}
        nextLabel={<Icon as={AngleRightIcon} />}
        breakLabel="..."
        breakClassName="break-me"
        pageCount={lastPage}
        forcePage={forcePage}
        marginPagesDisplayed={2}
        pageRangeDisplayed={3}
        onPageChange={({ selected }) => {
          onPaginate(selected + 1);
        }}
        containerClassName="pagination"
        activeClassName="active"
      />
    </Flex>
  );
};

export default Pagination;
