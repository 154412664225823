import { extendTheme, DefaultChakraTheme } from '@chakra-ui/react';

import customColors from './colors';
import configTheme from './config';

const customTheme: DefaultChakraTheme = extendTheme({
  config: {
    ...configTheme,
  },
  colors: {
    ...customColors,
  },
  styles: {
    global: {
      'html, body': {
        background: 'gray.50',
      },
    },
  },
  fonts: {
    body: 'Poppins, system-ui, sans-serif',
    heading: 'Poppins, system-ui, sans-serif',
    mono: 'Menlo, monospace',
  },
  fontWeights: {
    bold: {
      100: 100,
      200: 200,
      300: 300,
      400: 400,
      500: 500,
      600: 600,
      700: 700,
      800: 800,
      900: 900,
    },
  },
  transition: {
    transitionTimingFunction: 'ease-in-out',
    transitionDuration: '200ms',
  },
  components: {
    Tooltip: {
      baseStyle: {
        borderRadius: 6,
        padding: 2,
      },
    },

    Select: {
      baseStyle: {
        borderRadius: 6,
      },
      defaultProps: {
        size: 'md',
        variant: 'gglass',
      },
      variants: {
        gglass: {
          field: {
            background: '#FFF',
            color: 'blackAplha',
            borderWidth: 1,
            borderRadius: 5,
            borderColor: 'gray.300',
            transitionDuration: '100ms',
            _readOnly: {
              bg: `gray.100`,
              pointerEvents: 'none',
              touchAction: 'none',
              tabIndex: -1,
              ariaDisabled: 'true',
            },
            _focus: {
              borderColor: 'blue.300',
            },
            _invalid: {
              borderColor: 'red.500',
              background: 'red.100',
              _placeholder: {
                color: 'red.600',
              },
            },
          },
          // addon: {
          //   borderColor: '#0f9',
          // },
        },
      },
    },
    Input: {
      variants: {
        gglass: {
          field: {
            background: '#FFF',
            color: 'blackAplha',
            borderWidth: 1,
            borderRadius: 5,
            borderColor: 'gray.300',
            errorBorderColor: 'red.300',
            transitionDuration: '100ms',
            _readOnly: {
              bg: `gray.100`,
            },
            _focus: {
              borderColor: 'blue.300',
            },
            _invalid: {
              borderColor: 'red.500',
              background: 'red.100',
              _placeholder: {
                color: 'red.600',
              },
            },
          },
        },
      },
      defaultProps: {
        size: 'md',
        variant: 'gglass',
      },
    },

    FormLabel: {
      baseStyle: {
        fontSize: 'sm',
        _invalid: {
          color: 'red.500',
        },
      },
    },
    FormError: {
      baseStyle: {
        text: {
          color: 'red.500',
          mb: 3,
        },
      },
    },

    Button: {
      defaultProps: {
        size: 'sm',
      },
    },
    IconButton: {
      defaultProps: {
        isRound: true,
      },
    },
    Badge: {
      baseStyle: {
        fontSize: 10,
      },
    },
    Table: {
      defaultProps: {
        size: 'sm',
        variant: 'striped',
      },
    },
    Td: {
      baseStyle: {
        bg: 'gray.400',
      },
    },
    Box: {
      defaultProps: {
        boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.1)',
      },
    },
  },
});

export default customTheme;
