import React, { useState, useCallback, useEffect, useContext } from 'react';
import NumberFormat from 'react-number-format';

import { InfoCircleFill as InfoCircleFillIcon } from '@styled-icons/bootstrap/InfoCircleFill';
import { Search as SearchIcon } from '@styled-icons/boxicons-regular/Search';
import api from 'services/api';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  Icon,
  Button,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Tooltip,
  SimpleGrid,
  Tag,
} from '@chakra-ui/react';

import Loader from 'components/Loader';
import { ModalRootProps } from 'components/Modal/Root';
import Pagination from 'components/Pagination';
import { Table, Thead, Tbody, Tr, Th, Td } from 'components/Table';

import AuthContext from 'contexts/AuthContext';

import useDebouncedState from 'hooks/useDebouncedState';
import useModal from 'hooks/useModal';
import useThrottledState from 'hooks/useThrottledState';

interface RecipientData {
  id: number;
  name: string;
  email: string;
  gateway_id: string;

  account: {
    id: number;
    name: string;
  };

  balance: {
    available: number;
    transferred: number;
    waiting_funds: number;
  };

  register_information: {
    name: string;
  };

  bank_account: {
    id: number;
    bank: {
      id: number;
      code: string;
      name: string;
      complete_name: string;
    };
    agency_number: string;
    agency_vd: string;
    account_number: string;
    account_vd: string;
    type: string;
    legal_name: string;
  };

  created_at: Date;
  updated_at: Date;
}

interface SelectedOwner {
  id: number;
  name: string;
}

interface ServicePagination {
  last_page: number;
}

interface Pagination {
  per_page: number;
  page: number;
}
const ModalRecipientLookup: React.FC<ModalRootProps> = ({
  onConfirm,
  handleClose,
  ...restProps
}) => {
  const handleConfirm = (recipient: RecipientData): void => {
    if (onConfirm && typeof onConfirm === typeof Function) {
      onConfirm(recipient);
      handleClose();
    }
  };

  const auth = useContext(AuthContext);

  const { openModal } = useModal();

  const [selectedOwner, setSelectedOwner] = useState<SelectedOwner | null>(
    null,
  );

  const [data, setData] = useState<RecipientData[]>([]);
  const [loading, setLoading] = useState(false);

  const [keyword, setKeyword] = useDebouncedState<string>('');
  const [pagination, setPagination] = useThrottledState<Pagination>(
    {
      per_page: 10,
      page: 1,
    },
    1000,
  );

  const [servicePagination, setServicePagination] = useState<ServicePagination>(
    { last_page: 1 },
  );

  const getData = useCallback(async () => {
    setLoading(true);
    setData([]);

    try {
      const response = await api.get('/recipient', {
        params: {
          'filter[keyword]': keyword,
          ...(['admin', 'owner'].includes(auth.user.role.name) &&
            selectedOwner?.id && {
              'filter[owner_id]': selectedOwner.id,
            }),
          page: pagination.page,
          per_page: pagination.per_page,
        },
      });

      const users = response.data;

      setData(users.data);
      setServicePagination(users.meta);
    } catch (err) {
      setData([]);
      setServicePagination({ last_page: 1 });
    } finally {
      setLoading(false);
    }
  }, [pagination, keyword, selectedOwner, auth]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <Modal {...restProps} scrollBehavior="inside" size="full">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Selecionar recebedor</ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <HStack>
            {['admin', 'owner'].includes(auth.user.role.name) && (
              <InputGroup>
                <Input
                  type="text"
                  placeholder="Owner"
                  isReadOnly
                  value={selectedOwner?.name}
                  variant="outline"
                />
                <InputRightElement width="auto" mr={1}>
                  <Button
                    leftIcon={<Icon as={SearchIcon} />}
                    isFullWidth
                    colorScheme="blue"
                    onClick={() => {
                      openModal('ownerLookup', {
                        onConfirm: (selected: SelectedOwner) => {
                          setSelectedOwner(selected);
                        },
                      });
                    }}
                  >
                    Procurar
                  </Button>
                </InputRightElement>
              </InputGroup>
            )}
          </HStack>

          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={3} my={3}>
            <HStack>
              <Text fontSize="smaller">Por página:</Text>
              <Select
                width="auto"
                defaultValue={pagination.per_page}
                onChange={(e) => {
                  setPagination({
                    ...pagination,
                    per_page: Number(e.target.value),
                  });
                }}
              >
                {[5, 10, 20, 50].map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </Select>
            </HStack>

            <HStack>
              <Input
                type="text"
                placeholder="Pesquisar"
                onChange={(e) => setKeyword(e.target.value)}
              />
            </HStack>
          </SimpleGrid>

          <Table>
            <Thead>
              <Tr>
                <Th>Recebedor</Th>
                <Th>Banco</Th>
                <Th>Nome</Th>
                <Th>Tipo</Th>
                <Th isNumeric>Saldo</Th>
                <Th>Ações</Th>
              </Tr>
            </Thead>
            <Tbody>
              {!loading &&
                data.map((item) => (
                  <Tr key={item.id}>
                    <Td>
                      {auth.user.role.name === 'admin' && (
                        <Text fontWeight="bold" mb={1}>
                          {item.account?.owner?.name}{' '}
                          <Tooltip
                            hasArrow
                            label="Owner responsável pelo recebedor"
                          >
                            <Icon as={InfoCircleFillIcon} color="grey" />
                          </Tooltip>
                        </Text>
                      )}
                      <Text mb={1}>{item.account?.name}</Text>
                      <Text>{item.gateway_id}</Text>
                    </Td>
                    <Td>
                      <Text>{item.bank_account?.bank?.complete_name}</Text>

                      {item.bank_account && (
                        <Text mt={1}>
                          <Tag size="sm" mr="1" bgColor="gray.300">
                            <Text
                              fontSize="smaller"
                              as="span"
                              fontWeight="bold"
                              mr="1"
                            >
                              AG:
                            </Text>
                            <Text fontSize="smaller" as="span">
                              {item.bank_account?.agency_number}
                              {item.bank_account?.agency_vd
                                ? ` - ${item.bank_account?.agency_vd}`
                                : ''}
                            </Text>
                          </Tag>

                          <Tag size="sm" bgColor="gray.300">
                            <Text
                              fontSize="smaller"
                              as="span"
                              fontWeight="bold"
                              mr="1"
                            >
                              CT:
                            </Text>
                            <Text fontSize="smaller" as="span">
                              {item.bank_account?.account_number}
                              {item.bank_account?.account_vd
                                ? ` - ${item.bank_account?.account_vd}`
                                : ''}
                            </Text>
                          </Tag>
                        </Text>
                      )}
                    </Td>
                    <Td>
                      <Text mb={1}>{item.bank_account?.legal_name}</Text>

                      <Text fontWeight="bold" fontSize="smaller">
                        <NumberFormat
                          displayType="text"
                          format={
                            item.bank_account?.document_type === 'cnpj'
                              ? '##.###.###/####-##'
                              : '###.###.###-##'
                          }
                          value={item.bank_account?.document_number}
                        />
                      </Text>
                    </Td>

                    <Td>
                      <Text mb={1}>
                        {item.bank_account?.type === 'conta_corrente'
                          ? 'Conta Corrente'
                          : 'Conta Poupança'}
                      </Text>
                    </Td>

                    <Td isNumeric>
                      <Text mb={1} fontWeight="bold">
                        <NumberFormat
                          displayType="text"
                          defaultValue={item.balance?.available || 0}
                          thousandSeparator="."
                          decimalSeparator=","
                          prefix="R$ "
                          decimalScale={2}
                          fixedDecimalScale={2}
                        />
                      </Text>
                    </Td>

                    <Td>
                      <Button
                        colorScheme="green"
                        onClick={() => handleConfirm(item)}
                      >
                        Selecionar
                      </Button>
                    </Td>
                  </Tr>
                ))}

              {loading && (
                <Tr>
                  <Td colSpan={10}>
                    <Loader />
                  </Td>
                </Tr>
              )}

              {!loading && data.length === 0 && (
                <Tr>
                  <Td colSpan={10} className="text-center">
                    Nenhum resultado encontrado
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>

          <Pagination
            lastPage={servicePagination.last_page}
            onPaginate={(selectedPage) => {
              setPagination({ ...pagination, page: selectedPage });
            }}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ModalRecipientLookup;
