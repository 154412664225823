import React, { useState, useCallback, useEffect } from 'react';

import api from 'services/api';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  TableContainer,
  Text,
  Button,
  HStack,
  Input,
  Select,
  SimpleGrid,
} from '@chakra-ui/react';

import Loader from 'components/Loader';
import { ModalRootProps } from 'components/Modal/Root';
import Pagination from 'components/Pagination';
import { Table, Thead, Tbody, Tr, Th, Td } from 'components/Table';

import useDebouncedState from 'hooks/useDebouncedState';
import useThrottledState from 'hooks/useThrottledState';

interface OwnerUserData {
  id: number;
  name: string;
  email: string;
  phone_number: string | null;
  status: string;
  type: string;
  created_at: Date;
  updated_at: Date;
}

interface ServicePagination {
  last_page: number;
}

interface Pagination {
  per_page: number;
  page: number;
}
const ModalOwnerLookup: React.FC<ModalRootProps> = ({
  // data = {},
  onConfirm,
  handleClose,
  ...restProps
}) => {
  const handleConfirm = (owner: OwnerUserData): void => {
    if (onConfirm && typeof onConfirm === typeof Function) {
      onConfirm(owner);
      handleClose();
    }
  };

  const [data, setData] = useState<OwnerUserData[]>([]);
  const [loading, setLoading] = useState(false);

  const [keyword, setKeyword] = useDebouncedState<string>('');
  const [pagination, setPagination] = useThrottledState<Pagination>(
    {
      per_page: 10,
      page: 1,
    },
    1000,
  );

  const [servicePagination, setServicePagination] = useState<ServicePagination>(
    { last_page: 1 },
  );

  const getData = useCallback(async () => {
    setLoading(true);
    setData([]);

    try {
      const response = await api.get('/owner', {
        params: {
          'filter[keyword]': keyword,
          // 'filter[account_id]': auth.user.account.id,
          page: pagination.page,
          per_page: pagination.per_page,
        },
      });

      const users = response.data;

      setData(users.data);
      setServicePagination(users.meta);
    } catch (err) {
      setData([]);
      setServicePagination({ last_page: 1 });
    } finally {
      setLoading(false);
    }
  }, [pagination, keyword]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <Modal {...restProps} scrollBehavior="inside" size="6xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Selecionar Owner</ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={3} my={3}>
            <HStack>
              <Text fontSize="smaller">Por página:</Text>
              <Select
                width="auto"
                defaultValue={pagination.per_page}
                onChange={(e) => {
                  setPagination({
                    ...pagination,
                    per_page: Number(e.target.value),
                  });
                }}
              >
                {[5, 10, 20, 50].map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </Select>
            </HStack>

            <HStack>
              <Input
                type="text"
                placeholder="Pesquisar"
                onChange={(e) => setKeyword(e.target.value)}
              />
            </HStack>
          </SimpleGrid>

          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>Nome</Th>
                  <Th>Data de cadastro</Th>
                  <Th>Email</Th>
                  <Th>Ações</Th>
                </Tr>
              </Thead>
              <Tbody>
                {!loading &&
                  data.map((item) => (
                    <Tr key={item.id}>
                      <Td>{item.name}</Td>

                      <Td>{new Date(item.created_at).toLocaleDateString()}</Td>

                      <Td>
                        <span>{item.email}</span>
                        <br />
                        <span>{item.phone_number}</span>
                      </Td>

                      <Td>
                        <Button
                          colorScheme="green"
                          onClick={() => handleConfirm(item)}
                        >
                          Selecionar
                        </Button>
                      </Td>
                    </Tr>
                  ))}

                {loading && (
                  <Tr>
                    <Td colSpan={10}>
                      <Loader />
                    </Td>
                  </Tr>
                )}

                {!loading && data.length === 0 && (
                  <Tr>
                    <Td colSpan={10} className="text-center">
                      Nenhum resultado encontrado
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </TableContainer>

          <Pagination
            lastPage={servicePagination.last_page}
            onPaginate={(selectedPage) => {
              setPagination({ ...pagination, page: selectedPage });
            }}
          />
        </ModalBody>

        {/* <ModalFooter>
          <HStack spacing="2">
            <Button colorScheme="blackAlpha" onClick={handleClose}>
              Cancelar
            </Button>
            <Button colorScheme="green" onClick={handleConfirm}>
              Salvar
            </Button>
          </HStack>
        </ModalFooter> */}
      </ModalContent>
    </Modal>
  );
};

export default ModalOwnerLookup;
