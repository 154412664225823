import { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import api from 'services/api';

import usePersistedState from './usePersistedState';

interface Recipient {
  id: number;
  name: string;
  email: string;

  account: {
    id: number;
    name: string;
  };

  balance: {
    available: number;
    transferred: number;
    waiting_funds: number;
  };

  register_information: {
    name: string;
  };

  bank_account: {
    id: number;
    bank: {
      id: number;
      code: string;
      name: string;
      complete_name: string;
    };
    agency_number: string;
    agency_vd: string;
    account_number: string;
    account_vd: string;
    type: string;
    legal_name: string;
  };

  created_at: Date;
  updated_at: Date;
}
interface User {
  id: number;
  email: string;
  name: string;
  role: {
    id: number;
    description: string;
    name: string;
    modules: [
      {
        id: number;
        description: string;
        key: string;
        name: string;
      },
    ];
  };
  account: {
    id: number;
    name: string;
    document_type: string;
    document_number: string;
    owner: {
      id: number;
      name: string;
    };
    pagarme: {
      enabled: boolean;
      bank_account_quantity: number;
    };
    recipients?: Recipient[];
  };
}

export interface Auth {
  user: User;
  token: string | null;
  validing: boolean;
  loading: boolean;
  authenticated: boolean;
  handleLogin(email: string, password: string): Promise<void>;
  handleLogout(): void;
}

function useAuth(): Auth {
  const history = useHistory();

  const [loading] = useState<boolean>(false);
  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const [token, setToken] = usePersistedState<string | null>(
    'gglass:auth',
    null,
  );
  const [validing, setValiding] = useState<boolean>(true);

  const [user, setUser] = useState<User>({} as User);

  const getAuth = useCallback(async () => {
    setValiding(true);

    try {
      const { data: userInfo } = await api.get('/auth');

      setUser(userInfo.data);
      setAuthenticated(true);
    } catch (err) {
      setAuthenticated(false);

      delete api.defaults.headers.Authorization;

      toast.warning('Sessão expirada! Faça login novamente.');

      // history.push('/login');
    } finally {
      setValiding(false);
    }
  }, []);

  const handleLogout = useCallback(
    (force?: boolean): void => {
      setValiding(true);

      try {
        if (!force) {
          api.delete('/auth');
        }
      } finally {
        setValiding(false);

        setAuthenticated(false);

        setToken(null);

        setUser({} as User);

        delete api.defaults.headers.Authorization;

        // history.push('/login');
      }
    },
    [setToken],
  );

  const handleLogin = async (
    email: string,
    password: string,
  ): Promise<void> => {
    try {
      const authUser = await api.post('/auth', {
        email,
        password,
      });

      const accessToken = authUser.data?.access_token;

      if (accessToken) {
        api.defaults.headers.Authorization = `Bearer ${accessToken}`;

        setToken(accessToken);
        setAuthenticated(true);

        history.push('/');
      }
    } catch (err) {
      throw new Error(err);
    }
  };

  useEffect(() => {
    if (token) {
      api.defaults.headers.Authorization = `Bearer ${token}`;

      getAuth();
    } else {
      setValiding(false);

      setAuthenticated(false);

      setToken(null);

      setUser({} as User);

      delete api.defaults.headers.Authorization;
    }
  }, [token, getAuth, setToken]);

  return {
    authenticated,
    user,
    validing,
    token,
    loading,
    handleLogin,
    handleLogout,
  };
}

export default useAuth;
