import React, { ReactElement, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import { motion, useAnimation } from 'framer-motion';

import { TableRowProps, Tr as ChakraTr } from '@chakra-ui/react';

import TableContext from 'contexts/TableContext';

interface ITrInnerProps extends TableRowProps {
  headers: Record<number, React.ReactElement>;
  bodies: Record<number, React.ReactElement>;
  inHeader: boolean;
  inBody: boolean;
  bodyIdx: number;
}

export type ITrProps = Omit<ITrInnerProps, 'headers', 'bodies'>;

const MotionTr = motion<TableRowProps>(ChakraTr);

const TrInner = (props: ITrInnerProps): React.ReactElement => {
  const [ref, inView] = useInView({ threshold: 0.4 });
  const animation = useAnimation();

  const {
    headers,
    bodies,
    children,
    inHeader,
    inBody,
    bodyIdx,
    ...rest
  } = props;

  if (headers && inHeader) {
    React.Children.map(props.children, (child, i) => {
      if (child) {
        headers[i] = (child as ReactElement).props.children;
      }
    });
  }

  if (bodies && inBody) {
    React.Children.map(props.children, (child, i) => {
      if (child) {
        bodies[i] = (child as ReactElement).props.children;
      }
    });
  }

  const childProps = (idx: number): React.ReactElement =>
    inHeader ? { key: idx } : { key: idx, columnKey: idx };

  useEffect(() => {
    if (inView) {
      animation.start('visible');
    }
  }, [animation, inView, inBody]);

  return (
    <MotionTr
      {...rest}
      ref={ref}
      animate={animation}
      initial={inBody ? 'hidden' : 'visible'}
      position="relative"
      variants={{
        visible: {
          opacity: 1,
          // scale: 1,
          top: 0,
          transition: {
            duration: 0.2 + (bodyIdx || 0) / 100,
          },
        },
        hidden: {
          opacity: 0.1,
          // scale: 0.95,
          top: -10,
        },
      }}

      // {...(inBody && {
      //   // animate: { opacity: [0.1, 1], scale: [0.95, 1] },
      //   // transition: { duration: 0.15 * (bodyIdx || 0) },

      //   variants: {
      //     visible: {
      //       opacity: 1,
      //       transition: {
      //         duration: 0.15 * (bodyIdx || 0),
      //       },
      //     },
      //     hidden: {
      //       opacity: 0.1,
      //       scale: 0.95,
      //     },
      //   },
      // })}
    >
      {children &&
        React.Children.map(children, (child, idx) => {
          return (
            React.isValidElement(child) &&
            React.cloneElement(child, childProps(idx))
          );
        })}
    </MotionTr>
  );
};

export const Tr = (props: ITrProps): React.ReactElement => {
  return (
    <TableContext.Consumer>
      {(headers, bodies) => (
        <TrInner {...props} headers={headers} bodies={bodies} />
      )}
    </TableContext.Consumer>
  );
};
