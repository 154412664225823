import React, { Suspense, lazy, useContext, useEffect } from 'react';
import ReactGA from 'react-ga';
import { withRouter, useLocation } from 'react-router-dom';

import FallbackSpinner from 'components/FallbackSpinner';

import AuthContext from 'contexts/AuthContext';
import { SidebarProvider } from 'contexts/SidebarContext';

const AuthRoutes = lazy(() => import('./AuthRoutes'));
const AppRoutes = lazy(() => import('./AppRoutes'));

const Routes: React.FC = () => {
  const { pathname, search } = useLocation();

  const { authenticated, validing } = useContext(AuthContext);

  useEffect(() => {
    ReactGA.set({ page: pathname }); // Update the user's current page
    ReactGA.pageview(pathname + search); // Record a pageview for the given page
  }, [pathname, search]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  if (validing) {
    return <FallbackSpinner />;
  }

  return (
    <Suspense fallback={<FallbackSpinner />}>
      <SidebarProvider>
        {authenticated ? <AppRoutes /> : <AuthRoutes />}
      </SidebarProvider>
    </Suspense>
  );
};

export default withRouter(Routes);
